import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\n//<![CDATA[\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/cs_CZ/all.js#xfbml=1&appId=\";\nfjs.parentNode.insertBefore(js, fjs);\n//]]>\n}(document, 'script', 'facebook-jssdk'));\n//<![CDATA[\n$(document).ready(function(){\n$('.popclose').click(function(){\n$('#blogerki_pop').hide();\nwindow.location.href=\"index.php\";\n})\n})\n//]]>\n"
          }}
        />
        <div
          style={{
            width: "950px",
            margin: "0 auto",
            background: "#fff url(/malinanordycka/img/bg-index.jpg) center 55px no-repeat",
            "padding-bottom": "20px"
          }}
        >
          <div id="header">
            <div id="menu">
              <a
                href="/malinanordycka/index"
                style={{
                  "font-weight": "bold"
                }}
              >
                domovská stránka
              </a>
              <a href="/malinanordycka/produkty">výrobky</a>
              <a href="/malinanordycka/malina-nordycka">
                ostružiník moruška
              </a>
              <div id="onindex">
                <img
                  src="/malinanordycka/img/on.gif"
                  width="5"
                  height="5"
                  alt
                />
              </div>
              <div className="lo">
                <a href="/malinanordycka/index">
                  <img
                    src="/malinanordycka/img/none.gif"
                    width="200"
                    height="55"
                    alt
                  />
                </a>
              </div>
            </div>
          </div>
          <div id="top">
            <h2
              style={{
                "font-weight": "bold",
                "font-family": "arial",
                "font-size": "24px"
              }}
            >
              NEUTROGENA
              <sup
                style={{
                  "font-size": "12px"
                }}
              >
                ®
              </sup>{" "}
              Nordic Berry – krásná a dokonale vyživená pokožka každý den.
            </h2>
            <ul
              style={{
                "line-height": "22px"
              }}
            >
              <li>Vítejte ve světě krásné a dokonale vyživené pokožky!</li>
              <li>
                Chcete své pokožce dát to, co nejvíce potřebuje k tomu, aby byla
                každý den optimálně hydratovaná? Objevte tajemství řady výrobků
                péče o pokožku NEUTROGENA
                <sup
                  style={{
                    "font-size": "9px"
                  }}
                >
                  ®
                </sup>{" "}
                Nordic Berry pro trojnásobné zvýšení hladiny hydratace pokožky*
                a mějte krásnou, jemnou a dokonale vyživenou pokožku každý den.
                <br />
                <br />
              </li>
            </ul>
            <div className="ipoziomu">
              *index hladiny hydratace 7 hodin po aplikaci produktu
            </div>
          </div>
          <div id="like_1"></div>
          <div id="cols">
            <div className="col">
              <img
                src="/malinanordycka/img/neutrogena.jpg"
                className="fr"
                width="112"
                height="156"
                alt
              />
              <h1
                style={{
                  "font-weight": "bold"
                }}
              >
                Výrobky
              </h1>
              <p>
                Vyzkoušejte řadu vyživujících výrobků péče o pokožku NEUTROGENA
                <sup
                  style={{
                    "font-size": "9px"
                  }}
                >
                  ®
                </sup>{" "}
                Nordic Berry, obohacenou o ostružiník morušku. Objevte jedinečné
                inovativní složení, které přináší trojnásobné zvýšení hladiny
                hydratace pokožky*.
                <br />
                <br />
                <span
                  style={{
                    "font-size": "9px"
                  }}
                >
                  *index hladiny hydratace, 7 hodin po aplikaci produktu
                </span>
              </p>
              <div className="fr">
                <a href="/malinanordycka/produkty">
                  <img
                    src="/malinanordycka/img/przeczytaj.gif"
                    className="fr"
                    width="121"
                    height="49"
                    alt
                  />
                </a>
              </div>
            </div>
            <div className="col">
              <img
                src="/malinanordycka/img/malina.jpg"
                className="fr"
                width="166"
                height="138"
                alt
              />
              <h1
                style={{
                  "font-weight": "bold"
                }}
              >
                Ostružiník <br />
                moruška
              </h1>
              <p>
                Tento maličký keřík má jedinečné vlastnosti. Objevte tajemství
                neocenitelného ostružiníku morušky.
                <br />
                <br />
                <br />
                <br />
              </p>
              <div className="fr">
                <a href="/malinanordycka/malina-nordycka">
                  <img
                    src="/malinanordycka/img/przeczytaj.gif"
                    className="fr"
                    width="121"
                    height="49"
                    alt
                  />
                </a>
              </div>
            </div>
            <div className="clr" />
          </div>
        </div>
        <div id="foo">
          <div id="foot"></div>
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html:
                "\nvar _gaq = _gaq || [];\n_gaq.push(['_setAccount', 'UA-35072974-1']);\n_gaq.push(['_trackPageview']);\n(function() {\nvar ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;\nga.src = ('https:' == document.location.protocol ? 'https://ssl' : 'http://www') + '.google-analytics.com/ga.js';\nvar s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);\n})();\n"
            }}
          />
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html:
                "\nvar _gaq = _gaq || [];\n_gaq.push(['_setAccount', 'UA-29029437-17']);\n_gaq.push(['_trackPageview']);\n(function() {\nvar ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;\nga.src = ('https:' == document.location.protocol ? 'https://ssl' : 'http://www') + '.google-analytics.com/ga.js';\nvar s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);\n})();\n"
            }}
          />
        </div>
      </div>
    );
  }
}

export default Page;
